* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html, body, #app {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  position: relative;
}

h1 {
  font-weight: bold;
  font-size: 24px;
  color: #04869b;
  margin-bottom: 30px;
}

h2 {
  font-weight: bold;
  font-size: 20px;
  color: #04869b;
  margin-bottom: 20px;
}

p {
  font-size: 18px;
  line-height: 28px
}

strong {
	/* color: var(--color-primary) */
  color: #000 !important;
  color: initial !important;
  font-weight: normal
}

a {
  text-decoration: none;
  color: inherit;
}

.fade-enter {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.01;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}

.fade-leave {
  width: 100%;
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}

div[data-react-toolbox=dialog] {
  max-height: 96%;
}

div[data-react-toolbox=dialog] > section{
  overflow: auto;
}

/* react-table styling */

.-btn.-btn.-btn.-btn.-btn {
  background-color: #04869b;
  color: white;
  font-weight: bold;
}

.-btn.-btn.-btn.-btn.-btn:hover {
  background-color: #05798c;
  color: white;
  font-weight: bold;
}

.-btn.-btn.-btn.-btn.-btn:disabled {
  background-color: lightgrey;
}

/* Other layout classes */

.absolute-fill.absolute-fill {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.relative.relative {
  position: relative;
}

.flex-row.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-col.flex-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.overflow-hidden.overflow-hidden {
  overflow: hidden
}

.overflow-auto.overflow-auto {
  overflow: auto
}

.flex-center.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/* Hide extraneous Google sign-in div */

#g_a11y_announcement {
  display: none;
}