.container {
  background-color: #ECEFF1;
  text-align: center;
  padding-top: 2em;
  padding-bottom: 2em;
  z-index: 100;
  position: relative;
}

.logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.logoContainer {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  height: 5em;
  overflow: hidden;
  padding: 10px 20px;
}

@media (max-width: 600px) {
  .logoContainer {
    height: 4em;
    padding: 5px 10px;
  }
}

.logo {
  max-height: 100%;
  max-width: 100%;
}

.email {
  color: #04869b
}

.email:hover {
  text-decoration: underline;
}

.steiner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: 140%;
  color: #04869b;
}

.steiner > .top {
  display: block;
}

.steiner > .bottom {
  display: block;
}
